import DynamicAccordion from "@components/ui/dynamic_accordion/DynamicAccordion";
import {
  amountFilterDropdown,
  countryDropdown,
  lenderTypesDropdown,
  // ratingDropdown,
  requirementsDropdown,
  serviceTypeDropdown,
  tenorFilterDropdown,
} from "@utils/data";
import Image from "next/image";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setAllFiltersState} from "state/features/magicSlice";
import {AppDispatch, RootState} from "state/store";
import {useRouter} from "next/router";
import {
  setFilter as setProviderFilter,
  clearFilters as clearProviderFilters,
} from "state/features/providersFilterSlice";
import {
  setFilter as setLenderFilter,
  clearFilters as clearLenderFilters,
} from "state/features/lendersFilterSlice";
import {
  setFilter as setLoanFilter,
  clearFilters as clearOfferFilters,
} from "state/features/filterSlice";

const ProjectXFilters = () => {
  const dispatch = useDispatch<AppDispatch>();
  const router = useRouter();
  const {pathname} = router;

  // Fetching total filter value from session storage
  const Total = JSON.parse(sessionStorage.getItem("total") as string);

  const [openAccordionIndex, setOpenAccordionIndex] = useState<
    number | string | null
  >(null);

  const toggleAccordion = (index: number | string) => {
    setOpenAccordionIndex(openAccordionIndex === index ? null : index);
  };

  const {countries: offerCountries} = useSelector(
    (state: RootState) => state.offers
  );
  const {countries: lenderCountries} = useSelector(
    (state: RootState) => state.allLenders
  );
  const {countries: providerCountries} = useSelector(
    (state: RootState) => state.allProviders
  );

  const {
    amount: offerAmount,
    tenor: offerTenor,
    country: offerCountry,
    requirement: offerRequirement,
    correlation: offerCorrelation,
  } = useSelector((state: RootState) => state.filter);
  const {
    lenderType,
    amount: lenderAmount,
    tenor: lenderTenor,
    country: lenderCountry,
    correlation: lenderCorrelation,
  } = useSelector((state: RootState) => state.lendersFilter);
  const {
    serviceType,
    country: providerCountry,
    correlation: providerCorrelation,
  } = useSelector((state: RootState) => state.providersFilter);

  const sameCorrelation = pathname.includes("lenders")
    ? lenderCorrelation
    : offerCorrelation;

  return (
    <div className="w-full h-full justify-end items-end flex">
      <div className="bg-white-100 h-[80vh] w-full lg:w-[450px] lg:h-full flex flex-col gap-5 relative">
        <div className="flex flex-row justify-between items-center p-3 border-b border-blue-1300">
          <h6 className="body-lg-bold inter text-primary">Filters</h6>
          <div
            className="cursor-pointer"
            onClick={() => dispatch(setAllFiltersState(false))}
          >
            <Image
              src={"/assets/icons/close_dark_blue.svg"}
              width={20}
              height={20}
              alt="Close icon"
            />
          </div>
        </div>
        <div>
          {pathname.includes("lenders") && (
            <div className="border-b border-blue-1300">
              <DynamicAccordion
                title={"Lender type"}
                isOpen={openAccordionIndex === "Lender type"}
                onToggle={() => toggleAccordion("Lender type")}
                extras="!border-none !border-b border-gray-800 !mb-0"
              >
                {lenderTypesDropdown.map((option, idx) => (
                  <li
                    key={option.option}
                    className="py-2 cursor-pointer capitalize hover:bg-gray-200 flex flex-row justify-between w-full parent-check-box"
                    onClick={() => {
                      const updateFilter = (currentArray: string[]) => {
                        const newArray = [...currentArray];
                        const optionIndex = newArray.indexOf(option.value);

                        if (optionIndex > -1) {
                          // Option is already in the array, remove it
                          newArray.splice(optionIndex, 1);
                        } else {
                          // Option is not in the array, add it
                          newArray.push(option.value);
                        }

                        return newArray;
                      };

                      dispatch(
                        setLenderFilter({
                          ["lenderType"]: updateFilter(lenderType),
                        })
                      );
                    }}
                  >
                    {option.option}
                    <span className="flex gap-2 items-center">
                      {sameCorrelation.lenderType && (
                        <p> {sameCorrelation.lenderType[option.option]} </p>
                      )}
                      <input
                        type="checkbox"
                        className="cursor-pointer mr-2 border border-gray-800 appearance-none h-[14px] w-[14px] rounded-[2px] checked:bg-green-600 checked:border-none focus:outline-none checked-input relative"
                        readOnly
                        checked={
                          lenderType.includes(option.value) &&
                          pathname.includes("lenders")
                        }
                      />
                    </span>
                  </li>
                ))}
              </DynamicAccordion>
            </div>
          )}

          {(pathname.includes("lenders") || pathname.includes("offers")) && (
            <div className="border-b border-blue-1300">
              <DynamicAccordion
                title="Amount"
                isOpen={openAccordionIndex === "Amount"}
                onToggle={() => toggleAccordion("Amount")}
                extras="!border-none !border-b border-gray-800 !mb-0"
              >
                {amountFilterDropdown.map((option, idx) => (
                  <li
                    key={option.option}
                    className="py-2 cursor-pointer capitalize hover:bg-gray-200 flex flex-row justify-between w-full parent-check-box"
                    onClick={() => {
                      const updateFilter = (currentArray: string[]) => {
                        const newArray = [...currentArray];
                        const optionIndex = newArray.indexOf(option.value);

                        if (optionIndex > -1) {
                          // Option is already in the array, remove it
                          newArray.splice(optionIndex, 1);
                        } else {
                          // Option is not in the array, add it
                          newArray.push(option.value);
                        }

                        return newArray;
                      };

                      if (pathname.includes("lenders")) {
                        dispatch(
                          setLenderFilter({
                            ["amount"]: updateFilter(lenderAmount),
                          })
                        );
                      } else {
                        dispatch(
                          setLoanFilter({["amount"]: updateFilter(offerAmount)})
                        );
                      }
                    }}
                  >
                    {option.option}
                    <span className="flex gap-2 items-center">
                      {sameCorrelation.amount && (
                        <p> {sameCorrelation.amount[option.option]} </p>
                      )}
                      <input
                        type="checkbox"
                        className="cursor-pointer mr-2 border border-gray-800 appearance-none h-[14px] w-[14px] rounded-[2px] checked:bg-green-600 checked:border-none focus:outline-none checked-input relative"
                        readOnly
                        checked={
                          (lenderAmount.includes(option.value) &&
                            pathname.includes("lenders")) ||
                          (offerAmount.includes(option.value) &&
                            pathname.includes("offers"))
                        }
                      />
                    </span>
                  </li>
                ))}
              </DynamicAccordion>
            </div>
          )}
          {(pathname.includes("lenders") || pathname.includes("offers")) && (
            <div className="border-b border-blue-1300">
              <DynamicAccordion
                title="Tenor"
                isOpen={openAccordionIndex === "Tenor"}
                onToggle={() => toggleAccordion("Tenor")}
                extras="!border-none !border-b border-gray-800 !mb-0"
              >
                {tenorFilterDropdown.map((option, idx) => (
                  <li
                    key={option.option}
                    className="py-2 cursor-pointer capitalize hover:bg-gray-200 flex flex-row justify-between w-full parent-check-box"
                    onClick={() => {
                      const updateFilter = (currentArray: string[]) => {
                        const newArray = [...currentArray];
                        const optionIndex = newArray.indexOf(option.value);

                        if (optionIndex > -1) {
                          // Option is already in the array, remove it
                          newArray.splice(optionIndex, 1);
                        } else {
                          // Option is not in the array, add it
                          newArray.push(option.value);
                        }

                        return newArray;
                      };

                      if (pathname.includes("lenders")) {
                        dispatch(
                          setLenderFilter({
                            ["tenor"]: updateFilter(lenderTenor),
                          })
                        );
                      } else {
                        dispatch(
                          setLoanFilter({["tenor"]: updateFilter(offerTenor)})
                        );
                      }
                    }}
                  >
                    {option.option}
                    <span className="flex gap-2 items-center">
                      {sameCorrelation.tenor && (
                        <p> {sameCorrelation.tenor[option.option]} </p>
                      )}
                      <input
                        type="checkbox"
                        className="cursor-pointer mr-2 border border-gray-800 appearance-none h-[14px] w-[14px] rounded-[2px] checked:bg-green-600 checked:border-none focus:outline-none checked-input relative"
                        readOnly
                        checked={
                          (lenderTenor.includes(option.value) &&
                            pathname.includes("lenders")) ||
                          (offerTenor.includes(option.value) &&
                            pathname.includes("offers"))
                        }
                      />
                    </span>
                  </li>
                ))}
              </DynamicAccordion>
            </div>
          )}
          {pathname.includes("offers") && (
            <div className="border-b border-blue-1300">
              <DynamicAccordion
                title="Country"
                isOpen={openAccordionIndex === "Country"}
                onToggle={() => toggleAccordion("Country")}
                extras="!border-none !border-b border-gray-800 !mb-0"
              >
                {(offerCountries ?? countryDropdown).map((option, idx) => (
                  <li
                    key={option.option}
                    className="py-2 cursor-pointer capitalize hover:bg-gray-200 flex flex-row justify-between w-full parent-check-box"
                    onClick={() => {
                      const updateFilter = (currentArray: string[]) => {
                        const newArray = [...currentArray];
                        const optionIndex = newArray.indexOf(option.value);

                        if (optionIndex > -1) {
                          // Option is already in the array, remove it
                          newArray.splice(optionIndex, 1);
                        } else {
                          // Option is not in the array, add it
                          newArray.push(option.value);
                        }

                        return newArray;
                      };

                      dispatch(
                        setLoanFilter({
                          ["country"]: updateFilter(offerCountry),
                        })
                      );
                    }}
                  >
                    {option.option}
                    <span className="flex gap-2 items-center">
                      {sameCorrelation.country && (
                        <p> {sameCorrelation.country[option.option]} </p>
                      )}
                      <input
                        type="checkbox"
                        className="cursor-pointer mr-2 border border-gray-800 appearance-none h-[14px] w-[14px] rounded-[2px] checked:bg-green-600 checked:border-none focus:outline-none checked-input relative"
                        readOnly
                        checked={
                          offerCountry.includes(option.value) &&
                          pathname.includes("offers")
                        }
                      />
                    </span>
                  </li>
                ))}
              </DynamicAccordion>
            </div>
          )}
          {pathname.includes("lenders") && (
            <div className="border-b border-blue-1300">
              <DynamicAccordion
                title="Country"
                isOpen={openAccordionIndex === "Country"}
                onToggle={() => toggleAccordion("Country")}
                extras="!border-none !border-b border-gray-800 !mb-0"
              >
                {(lenderCountries ?? countryDropdown).map((option, idx) => (
                  <li
                    key={option.option}
                    className="py-2 cursor-pointer capitalize hover:bg-gray-200 flex flex-row justify-between w-full parent-check-box"
                    onClick={() => {
                      const updateFilter = (currentArray: string[]) => {
                        const newArray = [...currentArray];
                        const optionIndex = newArray.indexOf(option.value);

                        if (optionIndex > -1) {
                          // Option is already in the array, remove it
                          newArray.splice(optionIndex, 1);
                        } else {
                          // Option is not in the array, add it
                          newArray.push(option.value);
                        }

                        return newArray;
                      };

                      dispatch(
                        setLenderFilter({
                          ["country"]: updateFilter(lenderCountry),
                        })
                      );
                    }}
                  >
                    {option.option}
                    <span className="flex gap-2 items-center">
                      {sameCorrelation.country && (
                        <p> {sameCorrelation.country[option.option]} </p>
                      )}
                      <input
                        type="checkbox"
                        className="cursor-pointer mr-2 border border-gray-800 appearance-none h-[14px] w-[14px] rounded-[2px] checked:bg-green-600 checked:border-none focus:outline-none checked-input relative"
                        readOnly
                        checked={
                          lenderCountry.includes(option.value) &&
                          pathname.includes("lenders")
                        }
                      />
                    </span>
                  </li>
                ))}
              </DynamicAccordion>
            </div>
          )}
          {pathname.includes("offers") && (
            <div className="border-b border-blue-1300">
              <DynamicAccordion
                title={"Requirements"}
                isOpen={openAccordionIndex === "Requirements"}
                onToggle={() => toggleAccordion("Requirements")}
                extras="!border-none !border-b border-gray-800 !mb-0"
              >
                {requirementsDropdown.map((option) => (
                  <li
                    key={option.option}
                    className="py-2 cursor-pointer capitalize hover:bg-gray-200 flex flex-row justify-between w-full parent-check-box"
                    onClick={() => {
                      const newRequirementArray = [...offerRequirement];
                      const optionIndex = newRequirementArray.indexOf(
                        option.value
                      );

                      if (optionIndex > -1) {
                        // Option is already in the array, remove it
                        newRequirementArray.splice(optionIndex, 1);
                      } else {
                        // Option is not in the array, add it
                        newRequirementArray.push(option.value);
                      }

                      // Dispatch the action with the updated array
                      dispatch(
                        setLoanFilter({["requirement"]: newRequirementArray})
                      );
                    }}
                  >
                    {option.option}
                    <span className="flex gap-2 items-center">
                      {offerCorrelation.requirement && (
                        <p> {offerCorrelation.requirement[option.option]} </p>
                      )}
                      <input
                        type="checkbox"
                        className="cursor-pointer mr-2 border border-gray-800 appearance-none h-[14px] w-[14px] rounded-[2px] checked:bg-green-600 checked:border-none focus:outline-none checked-input relative"
                        readOnly
                        checked={offerRequirement.includes(option.value)}
                      />
                    </span>
                  </li>
                ))}
              </DynamicAccordion>
            </div>
          )}

          {pathname.includes("providers") && (
            <div className="border-b border-blue-1300">
              <DynamicAccordion
                title="Service type"
                isOpen={openAccordionIndex === "Service type"}
                onToggle={() => toggleAccordion("Service type")}
                extras="!border-none !mb-0"
              >
                {serviceTypeDropdown.map((option, idx) => (
                  <li
                    key={option.option}
                    className="py-2 cursor-pointer capitalize hover:bg-gray-200 flex flex-row justify-between w-full parent-check-box"
                    onClick={() => {
                      const newServiceTypeArray = [...serviceType];
                      const optionIndex = newServiceTypeArray.indexOf(
                        option.value
                      );

                      if (optionIndex > -1) {
                        // Option is already in the array, remove it
                        newServiceTypeArray.splice(optionIndex, 1);
                      } else {
                        // Option is not in the array, add it
                        newServiceTypeArray.push(option.value);
                      }

                      // Dispatch the action with the updated array
                      dispatch(
                        setProviderFilter({
                          ["serviceType"]: newServiceTypeArray,
                        })
                      );
                    }}
                  >
                    {option.option}
                    <span className="flex gap-2 items-center">
                      {providerCorrelation.serviceType && (
                        <p>
                          {" "}
                          {providerCorrelation.serviceType[option.option]}{" "}
                        </p>
                      )}
                      <input
                        type="checkbox"
                        className="cursor-pointer mr-2 border border-gray-800 appearance-none h-[14px] w-[14px] rounded-[2px] checked:bg-green-600 checked:border-none focus:outline-none checked-input relative"
                        readOnly
                        checked={serviceType.includes(option.value)}
                      />
                    </span>
                  </li>
                ))}
              </DynamicAccordion>
            </div>
          )}
          {pathname.includes("providers") && (
            <div className="border-b border-blue-1300">
              <DynamicAccordion
                title="Country"
                isOpen={openAccordionIndex === "Country"}
                onToggle={() => toggleAccordion("Country")}
                extras="!border-none !border-b border-gray-800 !mb-0"
              >
                {(providerCountries ?? countryDropdown).map((option, idx) => (
                  <li
                    key={option.option}
                    className="py-2 cursor-pointer capitalize hover:bg-gray-200 flex flex-row justify-between w-full parent-check-box"
                    onClick={() => {
                      const newProviderCountryArray = [...providerCountry];
                      const optionIndex = newProviderCountryArray.indexOf(
                        option.value
                      );

                      if (optionIndex > -1) {
                        // Option is already in the array, remove it
                        newProviderCountryArray.splice(optionIndex, 1);
                      } else {
                        // Option is not in the array, add it
                        newProviderCountryArray.push(option.value);
                      }

                      // Dispatch the action with the updated array
                      dispatch(
                        setProviderFilter({
                          ["country"]: newProviderCountryArray,
                        })
                      );
                    }}
                  >
                    {option.option}
                    <span className="flex gap-2 items-center">
                      {providerCorrelation.country && (
                        <p> {providerCorrelation.country[option.option]} </p>
                      )}
                      <input
                        type="checkbox"
                        className="cursor-pointer mr-2 border border-gray-800 appearance-none h-[14px] w-[14px] rounded-[2px] checked:bg-green-600 checked:border-none focus:outline-none checked-input relative"
                        readOnly
                        checked={providerCountry.includes(option.value)}
                      />
                    </span>
                  </li>
                ))}
              </DynamicAccordion>
            </div>
          )}
        </div>

        <div className="flex flex-col-reverse w-full h-fit p-3 lg:flex-row gap-2 absolute bottom-0 border-t border-gray-800">
          <button
            className="btn-large text-primary six-15 text-center lg:w-fit border border-blue-1300"
            onClick={() => {
              if (pathname.includes("lenders")) {
                dispatch(clearLenderFilters());
              } else if (pathname.includes("offers")) {
                dispatch(clearOfferFilters());
              } else {
                dispatch(clearProviderFilters());
              }
            }}
          >
            Reset
          </button>
          {/* TODO - Fix results number display */}
          <button
            className="btn-large btn-primary text-center w-full"
            onClick={() => dispatch(setAllFiltersState(false))}
          >
            Show results ({Total ?? 0})
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProjectXFilters;
